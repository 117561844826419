(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/modal/views/modal-header.js') >= 0) return;  svs.modules.push('/components/components/modal/views/modal-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.modal=_cmps.modal||{};
_cmps.modal.templates=_cmps.modal.templates||{};
svs.components.modal.templates.modal_header = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"modal-content modal-content-buttons\" tabindex=\"0\"></div>\n<header class=\"modal-header row\">\n    <span class=\"modal-header-button-left\"><button type=\"button\" class=\"btn btn-100 modal-button-left\">Avbryt</button></span>\n    <h3 class=\"modal-header-title\" id=\"modal-header-title\"><span class=\"js-top-center-content modal-header-title-content modal-title\">Svenska Spel</span></h3>\n    <span class=\"modal-header-button-right\"><button type=\"button\" class=\"btn btn-100 modal-button-right\">Klar</button></span>\n</header>";
},"useData":true});
Handlebars.partials['components-components-modal-modal_header'] = svs.components.modal.templates.modal_header;
})(svs, Handlebars);


 })(window);