(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/modal/assets/javascripts/modal.js') >= 0) return;  svs.modules.push('/components/components/modal/assets/javascripts/modal.js');

var svs = svs || {};

(function(_, svs) {
  'use strict';

  var log = svs.core.log.getLogger('component:modal');

  svs.components = svs.components || {};

  svs.components.Modal = function(element) {
    var $el = element.find('.js-modal-container');
    var defaultHeaderClass = 'header-default';

    if (svs.core.detect.tenant('lb')) {
      defaultHeaderClass = 'header-sport';
    } else if (svs.core.detect.tenant('tb')) {
      defaultHeaderClass = 'header-tur';
    }

    if ($el.length === 0) {
      element.append('<div class="js-modal-container modal-container" tabindex="0" role="dialog"></div>');
      $el = element.find('.js-modal-container');
    }
    _.mixin({
      deepClone: function(pObject) {
        return JSON.parse(JSON.stringify(pObject));
      }
    });
    var activeElBeforeOpen = null;
    var currentSubView = null;
    var gotoUrl = null;
    var $modalHeader = null;
    var $leftButton = null;
    var jsLeftButton = null;
    var jsRightButton = null;
    var $rightButton = null;
    var $title = null;
    var $scrollArea = null;
    var observer = null;
    var defaultsHeaderOptions = {
      title: 'Title',
      headerClass: defaultHeaderClass,
      contentClass: 'modal-content-default',
      leftButton: {
        text: 'Avbryt',
        visible: true,
        disabled: false,
        cssClass:
            svs.core.detect.formfactor.mobile() && svs.core.detect.tenant('mb') ?
              'btn-transparent-inverted' :
              'btn-transparent-default'
      },
      rightButton: {
        text: 'Klar',
        visible: true,
        disabled: false,
        cssClass:
            svs.core.detect.formfactor.mobile() && svs.core.detect.tenant('mb') ?
              'btn-transparent-inverted' :
              'btn-transparent-default'
      },
      modalHistory: true,
      triggerScrollEnd: false,
      doubleWidth: false,
      quadrupleWidth: false,
      loginModal: false,
      onHide: function() { }
    };
    var headerOptions;
    var swiperOptions = {
      scrollbars: false,
      scrollingX: false,
      scrollingY: true,
      alwaysScroll: false,
      updateOnWindowResize: true,
      updateOnChanges: true,
      windowScrollingActiveFlag: 'modalScrolling'
    };
    var swiper = null;
    var updateHeightOnChange = false;

    function startMutationObserver() {
      observer = new MutationObserver(function() {
        svs.components.Modal.resize($scrollArea, currentSubView, innerHeight);
      });

      observer.observe($el[0], { childList: true, subtree: true });
    }

    function unbindEvents(element) {
      element.off('click', '.modal-button-left');
      element.off('click', '.modal-button-right');
      element.off('click', '[data-url]');
      element.off('touchmove');
    }

    return {
      bindElements: function() {
        $el.html(svs.components.modal.templates.modal_header());
        $el.attr('aria-labelledby', 'modal-header-title');
        $modalHeader = $el.find('.modal-header');
        $leftButton = $el.find('.modal-button-left');
        jsLeftButton = document.querySelector('.modal-button-left');
        jsRightButton = document.querySelector('.modal-button-right');
        $rightButton = $el.find('.modal-button-right');
        $title = $el.find('.modal-title');
        $scrollArea = $el.find('.modal-content');
      },
      orientationChange: function() {
        if (svs.core.detect.formfactor.mobile()) {
          $el.css('min-height', window.innerHeight);
        }
        this.resize($scrollArea, currentSubView, innerHeight);
      },
      show: function(ModalViewClass, options, newHeaderOptions, viewOptions) {
        activeElBeforeOpen = document.activeElement ? document.activeElement : document.body;
        headerOptions = _.deepClone(defaultsHeaderOptions);
        svs.components.loader.show();
        if (currentSubView) {
          unbindEvents($el);
        }

        if (options && options.escHandler) {
          this.escHandler = options.escHandler;
        }

        updateHeightOnChange = options && options.updateHeightOnChange;

        var innerHeight =
          viewOptions && viewOptions.forcedHeight ?
            viewOptions.forcedHeight :
            window.innerHeight;
        var self = this;
        var modalPath = 'modal';
        var tab = '';

        this.keyListenerId = svs.utils.keylistener.addKeyboardListener(function(event) {
          if (viewOptions && viewOptions.disableReturnKeyPress) {
            self.handleKeys(event, true);
          } else {
            self.handleKeys(event, false);
          }
        });

        if (viewOptions && viewOptions.disableSwiperOverflowScrolling) {
          swiperOptions.disableOverflowScrolling = true;
        }

        this.bindElements();

        $el.css('height', '100%');
        $el.css('min-height', innerHeight);
        $el.removeClass('modal-double-width');

        if (newHeaderOptions && newHeaderOptions.doubleWidth) {
          $el.addClass('modal-double-width');
        }

        if (newHeaderOptions && newHeaderOptions.quadrupleWidth) {
          $el.addClass('modal-quadruple-width');
        }

        this.setHeaderOptions(newHeaderOptions || defaultsHeaderOptions);

        this.modalHistory =
          this.getHeaderOptions().modalHistory && Modernizr.history;

        log.info('Modal initiated with' +
          (this.modalHistory ? '' : 'out') +
          ' history.');

        if (
          svs.utils.ns.isNamespace('React.isValidElement') &&
          typeof React.isValidElement === 'function' &&
          React.isValidElement(ModalViewClass) &&
          svs.utils.ns.isNamespace('ReactDOM.render') &&
          typeof ReactDOM.render === 'function'
        ) {
          $scrollArea.empty();
          ReactDOM.render(ModalViewClass, $scrollArea.get()[0]);

          svs.events.subscribe('modalContentResize', function() {
            self.resize($scrollArea, currentSubView, innerHeight, null);
          });
        } else if (
          typeof ModalViewClass === 'string' ||
          typeof ModalViewClass === 'object'
        ) {
          currentSubView = null;
          $scrollArea.empty().append(ModalViewClass);

          if (swiper) {
            swiper.destroy();
          }

          if ($scrollArea.find('.js-tabs-tabs').length === 0) {
            swiper = svs.components.swiper.init($scrollArea[0], swiperOptions);
          }
        } else {
          if (currentSubView && typeof currentSubView.destroy === 'function') {
            currentSubView.destroy();
          }
          currentSubView = new ModalViewClass(options || null);
          currentSubView.render();
          $scrollArea.empty().append(currentSubView.$el);

          if (typeof currentSubView.triggerMethod === 'function') {
            currentSubView.triggerMethod('attach');
          }

          if (swiper) {
            swiper.destroy();
          }

          if ($scrollArea.find('.js-tabs-tabs').length === 0) {
            swiper = svs.components.swiper.init($scrollArea[0], swiperOptions);
          }

          if (headerOptions.triggerScrollEnd) {
            swiper.addEventListener('reachedend', function() {
              currentSubView.trigger('reachedend');
            });
          }
          currentSubView.on('modalClose', this.hide, this);
          currentSubView.on(
            'modalContentResize',
            function(animated) {
              if (!svs.core.detect.formfactor.mobile()) {
                self.resize($scrollArea, currentSubView, innerHeight, animated);
              }
              if (swiper) {
                swiper.updateDimensions();
              }
            },
            this
          );
        }

        if (svs.core.detect.formfactor.mobile()) {
          this.resize($scrollArea, currentSubView, innerHeight);
          $el.css('top', $(window).height() + 60);
          $el.show();

          $el.animate(
            {
              top: 0,
              useTranslate3d: true
            },
            svs.core.data.capabilities.browser === 'android' ? 0 : 200,
            'easeOutCirc',
            function() {
              if (swiper) {
                swiper.updateDimensions();
                svs.components.swiper.disableBodyScroll();
              }
            }
          );

          if (svs.core.data.capabilities.osVersion === 'iphone os 7') {
            $el.css('position', 'relative');
            setTimeout(function() {
              $el.css('position', 'fixed');
            }, 10);
          }
        } else {
          $el.addClass('modal-animate-start').show();
          setTimeout(function() {
            $el.addClass('modal-animate-started');

            setTimeout(function() {
              self.resize($scrollArea, currentSubView, innerHeight);

              if (swiper) {
                swiper.updateDimensions();
                if (swiper.refresh) {
                  swiper.refresh();
                }
                svs.components.swiper.disableBodyScroll();
              }
            }, 100);
          }, 10);
        }
        $el.focus();
        svs.core.detect.orientation.on('change', this.orientationChange, this);

        $el.on('click', '.modal-button-left', $.proxy(this.buttonLeft, this));
        $el.on('click', '.modal-button-right', $.proxy(this.buttonRight, this));
        $el.on('click', '[data-url]', $.proxy(this.routeLink, this));

        $el.on('touchmove', function(e) {
          var target = $(e.target);
          if (target.parents('.modal-content').length === 0) {
            e.preventDefault();
          }
        });

        if (svs.core.data.capabilities.browser === 'android') {
          window.setTimeout(window.scrollTo(0, 1), 1);
        }

        if (this.modalHistory) {
          this.onPopstate = function() {
            if (svs.components.Modal.modalHistory) {
              if (
                newHeaderOptions &&
                typeof newHeaderOptions.onHideByBrowserBack === 'function'
              ) {
                newHeaderOptions.onHideByBrowserBack();
              }
              svs.components.Modal.hide(true);
            }
          };
          var pushModalToHistory = true;
          if (
            typeof newHeaderOptions.dontPushModalToHistory === 'boolean' &&
            newHeaderOptions.dontPushModalToHistory === true
          ) {
            pushModalToHistory = false;
          }

          if (pushModalToHistory) {
            log.debug(
              'Pushing to history: ',
              { type: 'modal' },
              '',
              document.location.href
            );
            history.pushState({ type: 'modal' }, '', document.location.href);
          }
        }
        $('input:not([type=checkbox], [type=radio], [type=button], [type=submit])')
          .focus(function() {
            var $swiperContainer = $('.js-swiper-container');
            $modalHeader.addClass('absolute');
            if (
              svs.core.detect.os.android() &&
              this.type !== 'date' &&
              $swiperContainer &&
              $swiperContainer.children().height() > $swiperContainer.height()
            ) {
              $swiperContainer.addClass('android-padding');
              this.parentNode.scrollIntoView();
            }
          })
          .focusout(function() {
            setTimeout(function() {
              if (!$(document.activeElement).is('input')) {
                $('.js-swiper-container').removeClass('android-padding');
                $modalHeader.removeClass('absolute');
              }
            }, 100);
          });

        if (
          newHeaderOptions !== undefined &&
          newHeaderOptions.title !== undefined
        ) {
          modalPath = newHeaderOptions.title;
        }

        if (currentSubView && currentSubView.tabView) {
          currentSubView.tabView.on('currentSlideChange', function(tab) {
            if (swiper && swiper.refresh) {
              swiper.refresh();
            }

            tab = '/' + tab;
            self.modalAnalyticsInfo(modalPath, tab);
          });

          if (
            currentSubView.tabView.options &&
            currentSubView.tabView.options.selected
          ) {
            tab = '/' + currentSubView.tabView.options.selected;
          } else {
            tab = '/' + currentSubView.tabView.options.tabs[0].id;
          }
        }

        this.modalAnalyticsInfo(modalPath, tab);

        this.modalHistory && $(window).one('popstate', this.onPopstate);

        if (
          !svs.core.detect.formfactor.mobile() &&
          newHeaderOptions &&
          newHeaderOptions.autoUpdateSizeOnDOMChange &&
          window.MutationObserver
        ) {
          startMutationObserver();
        }
        if (newHeaderOptions && newHeaderOptions.loginModal) {
          $el.addClass('modal-container-login');
        }
        svs.components.loader.hide();

        if (viewOptions && viewOptions.onLoad) {
          viewOptions.onLoad();
        }
      },
      modalAnalyticsInfo: function(modalPath, tab) {
        modalPath = modalPath || '';
        tab = tab || '';
        if (svs.components.analytics && svs.components.analytics.trackVirtual) {
          svs.components.analytics.trackVirtual(modalPath + tab, '', {
            isModal: true
          });
        }
      },

      resize: function($scrollArea, currentSubView, innerHeight, animated) {
        var headerHeight =
          $('.modal-header').outerHeight() +
          $('.modal-header').position().top * 2; 
        var contentMaxHeight = innerHeight - headerHeight;
        var currentHeight;
        if (currentSubView) {
          currentHeight = currentSubView.$el.outerHeight();
        }

        var newHeight;
        if (updateHeightOnChange && !svs.core.detect.formfactor.mobile()) {
          newHeight = this.getGameGuideHeight();
        } else if (
          currentHeight > contentMaxHeight ||
            swiper && $scrollArea.outerHeight() > contentMaxHeight
        ) {
          newHeight = contentMaxHeight;
        } else if (
          currentHeight > 0 &&
            !svs.core.detect.formfactor.mobile()
        ) {
          newHeight = currentHeight + 20;
        } else {
          newHeight = svs.core.detect.formfactor.mobile() ? '100%' : 'auto';
        }
        if (animated) {
          var transitionprop = 'height 200ms';
          $scrollArea.css({
            webkitTransition: transitionprop,
            mozTransition: transitionprop,
            OTransition: transitionprop,
            transition: transitionprop
          });
        }
        $scrollArea.height(newHeight);

        if (currentSubView && currentSubView.tabView) {
          if (currentSubView.tabView.setMaxHeight) {
            currentSubView.tabView.setMaxHeight(contentMaxHeight);
          }
          currentSubView.tabView.resize();
        }
      },

      onPopstate: function() {
        if (typeof Backbone !== 'undefined') {
          svs.components.Modal.hide();
        }
      },
      hide: function(isHistoryNavigation) {
        if (typeof isHistoryNavigation === 'undefined') {
          isHistoryNavigation = true;
        }
        svs.utils.keylistener.removeKeyboardListener(this.keyListenerId);
        activeElBeforeOpen && activeElBeforeOpen.focus();
        svs.components.swiper.enableBodyScroll();

        $el = element.find('.js-modal-container');
        var modalexists = $el.find('.modal-button-left').length > 0;
        if ($el.is(':visible')) {
          unbindEvents($el);

          $el.removeClass('modal-container-login');
          if (
            svs.utils.ns.isNamespace('ReactDOM.unmountComponentAtNode') &&
            typeof ReactDOM.unmountComponentAtNode === 'function' &&
            $scrollArea.get().length > 0
          ) {
            ReactDOM.unmountComponentAtNode($scrollArea.get()[0]);
          }

          if (swiper) {
            swiper.destroy();
            swiper = undefined;
          }

          if (svs.core.detect.formfactor.mobile()) {
            var toPos = $(window).height() + 60;

            $el.animate(
              {
                top: toPos + 'px',
                useTranslate3d: true
              },
              svs.core.data.capabilities.browser === 'android' ? 0 : 200,
              'easeInCirc',
              function() {
                $el.hide();
                headerOptions = _.deepClone(defaultsHeaderOptions);

                if (currentSubView) {
                  currentSubView.off('modalClose', this.hide, this);

                  if (currentSubView.destroy) {
                    currentSubView.destroy();
                  } else if (currentSubView.dispose) {
                    currentSubView.dispose();
                  }
                  currentSubView = null;
                }
              }
            );
          } else {
            $el.hide();
            $el.removeClass('modal-animate-start modal-animate-started');

            headerOptions = _.deepClone(defaultsHeaderOptions);

            if (currentSubView) {
              currentSubView.off('modalClose', this.hide, this);
              if (currentSubView.destroy) {
                currentSubView.destroy();
              } else if (currentSubView.dispose) {
                currentSubView.dispose();
              }
              currentSubView = null;
            }

            observer && observer.disconnect();
          }

          svs.core.detect.orientation.off(
            'change',
            this.orientationChange,
            this
          );

          if (gotoUrl !== null) {
            if (gotoUrl.indexOf('http://') === 0) {
              window.location.href = gotoUrl;
            }
            gotoUrl = null;
          }
        }

        if (this.modalHistory && isHistoryNavigation) {
          this.modalHistory = false;
          if (
            history.state &&
            history.state.type &&
            history.state.type === 'modal'
          ) {
            history.back();
          }
        }

        if (this.escHandler) {
          this.escHandler.callback();
        }

        return modalexists;
      },
      buttonLeft: function(e) {
        e.preventDefault();
        if (
          headerOptions.leftButton.visible &&
          Object.prototype.hasOwnProperty.call(headerOptions.leftButton, 'callback')
        ) {
          if (headerOptions.leftButton.callback.call(currentSubView)) {
            this.hide();
          }
        } else {
          this.hide();
        }
      },
      buttonRight: function(e) {
        e.preventDefault();
        if (
          headerOptions.rightButton.visible &&
          Object.prototype.hasOwnProperty.call(headerOptions.rightButton, 'callback')
        ) {
          if (headerOptions.rightButton.callback.call(currentSubView)) {
            this.hide();
          }
        } else {
          this.hide();
        }
      },
      getHeaderOptions: function() {
        return headerOptions;
      },
      setHeaderOptions: function(newHeaderOptions) {
        headerOptions = {
          title: newHeaderOptions.title,
          headerClass:
            newHeaderOptions.headerClass || headerOptions.headerClass,
          contentClass:
            newHeaderOptions.contentClass || headerOptions.contentClass,
          leftButton: _.extend(
            headerOptions.leftButton,
            newHeaderOptions.leftButton
          ),
          rightButton: _.extend(
            headerOptions.rightButton,
            newHeaderOptions.rightButton
          ),
          modalHistory: _.isBoolean(newHeaderOptions.modalHistory) ?
            newHeaderOptions.modalHistory :
            headerOptions.modalHistory,
          triggerScrollEnd: _.isBoolean(newHeaderOptions.triggerScrollEnd) ?
            newHeaderOptions.triggerScrollEnd :
            headerOptions.triggerScrollEnd
        };

        $modalHeader.addClass(headerOptions.headerClass);
        $scrollArea.addClass(headerOptions.contentClass);

        $leftButton.toggle(headerOptions.leftButton.visible);
        $leftButton.html(headerOptions.leftButton.text);
        $leftButton.addClass(headerOptions.leftButton.cssClass);
        $leftButton.prop('disabled', headerOptions.leftButton.disabled);

        $rightButton.toggle(headerOptions.rightButton.visible);
        $rightButton.html(headerOptions.rightButton.text);
        $rightButton.addClass(headerOptions.rightButton.cssClass);
        $rightButton.prop('disabled', headerOptions.rightButton.disabled);

        $title.html(headerOptions.title);
      },
      handleKeys: function(event) {
        if (event.code && event.code.toLowerCase() === 'tab' && event.shiftKey) {
          if (document.querySelector('.modal-content') === document.activeElement) {
            event.preventDefault();
            $scrollArea.focus();
          }
        } else if (event.code && event.code.toLowerCase() === 'tab') {
          var activeEl = document.activeElement;
          if (jsLeftButton === activeEl && (!headerOptions.rightButton.visible || jsRightButton.disabled)) {
            event.preventDefault();
            $scrollArea.focus();
          } else if (jsRightButton === activeEl) {
            event.preventDefault();
            $scrollArea.focus();
          }
        } else if (event.keyCode === svs.utils.keylistener.KEY_ESC) {
          $('.modal-button-left').click();
        }
      },
      routeLink: function(e) {
        e.preventDefault();
        gotoUrl = $(e.currentTarget).data('url');
        this.hide();
      },
      stopClick: function(e) {
        e.stopPropagation();
      },

      getSwiper: function() {
        return swiper;
      },

      currentView: function() {
        return currentSubView;
      },

      isOpen: function() {
        var $modalContainer = $('.modal-container');
        var display = $modalContainer.css('display');

        return display === 'block';
      },

      getGameGuideHeight: function() {
        var tabSlider = $('#tabs-slider').find('.slider-slide-active');
        var contentSlider = $('#content-slider').find('.slider-slide-active');
        var newHeight = tabSlider.height() + contentSlider.height() + 20;

        return newHeight;
      },

      getContentElement: function() {
        return $el.find('.swiper-container').get()[0];
      }
    };
  };

  svs.components.Modal = new svs.components.Modal($('body'));
})(_, svs);


 })(window);